import React from "react"
import { Layout } from "../components/Layout/Layout"
import { graphql } from "gatsby"
import { PageTitle } from "../components/PageTitle/PageTitle"
import { Eversports } from "../components/Eversports/Eversports"

interface CourseBookingProps {
  data: {
    cms: {
      page: {
        title: string
      }
    }
  }
}

export const CourseBooking: React.FC<CourseBookingProps> = ({ data }) => {
  return (
    <Layout withPadding>
      <PageTitle>{data.cms.page.title}</PageTitle>
      <Eversports
        title="course schedule"
        widgetCode="t4ez9i"
        height={1000}
        className="w-11/12 bg-white px-8 py-6"
      />
    </Layout>
  )
}

export default CourseBooking

export const query = graphql`
  {
    cms {
      page(id: "/kursplan", idType: URI) {
        title
      }
    }
  }
`
